<template>
  <div class="components-filter-box p-2">
    <div class="d-flex align-items-center">
      <div class="title flex-fill">Filtrar resultados</div>
      <button class="btn text-primary" @click="clear">
        <small class="font-weight-bold">Limpar</small>
      </button>
    </div>
    <search-input
      v-if="allowSearch"
      placeholder="Pesquisar"
      v-model="search"
      class="mb-3"
      size="sm"
    />
    <div class="content-area overflow-y-scroll">
      <checkbox-group :options="optionsMap" v-model="selected" />
    </div>
    <div class="option d-flex align-items-center justify-content-between pt-4">
      <button
        class="btn btn-block btn-outline-secondary btn-sm mr-1"
        @click="clear"
      >
        Limpar
      </button>
      <button class="btn btn-block m-0 btn-primary btn-sm" @click="submit">
        Aplicar
      </button>
    </div>
  </div>
</template>
<script>
import { normalizeText } from "@/functions/helpers.js";
export default {
  props: {
    allowSearch: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      required: false,
      default: () => []
    },
    mapKey: {
      type: String,
      required: false,
      default: ""
    },
    value: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data() {
    return {
      search: "",
      innerOptions: this.options,
      selected: [...this.value]
    };
  },
  watch: {
    search(v) {
      if (v && v.length) {
        this.innerOptions = this.options.filter(
          o => normalizeText(JSON.stringify(o)).indexOf(normalizeText(v)) !== -1
        );
      } else {
        this.innerOptions = this.options;
      }
    },
    options(newV) {
      this.innerOptions = newV;
    },
    selected(v) {
      if (!v.length) {
        this.$emit("on-empty");
      }
    }
  },
  computed: {
    optionsMap() {
      return this.options.map(({ id, name }) => ({
        text: name,
        value: id,
        id
      }));
    }
  },
  methods: {
    clear() {
      this.selected = [];
      this.search = "";
      this.innerOptions = this.options;
      this.$emit("input", []);
    },
    submit() {
      this.$emit("input", this.selected);
    }
  }
};
</script>
<style lang="scss" scoped>
.components-filter-box {
  background: #f5f5f5;
  border: 1px solid #cfcfcf;
  box-sizing: border-box;
  border-radius: 4px;

  .title {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.015em;
    color: #202124;
  }

  .option {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 150%;
    display: flex;
    align-items: center;
    letter-spacing: 0.015em;
    color: #202124;
    padding: 3px 2px;
  }

  .content-area {
    max-height: 300px;
  }

  ::v-deep .form-check-label {
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0.015em;
    color: #0a0a0a;
  }
}
</style>
