<template>
  <div class="components-proposal-new">
    <responsive-modal v-model="value">
      <div class="new-proposal-comp overflow-y-scroll p-2 px-lg-4">
        <h5 class="text-center font-weight-bold text-dark mb-3">
          Faça sua proposta abaixo
        </h5>
        <p class="indicator text-center text-dark" style="font-size: 14px;">
          Explique como você pode ajudar prestando o serviço referente ao
          projeto.
        </p>
        <form-group
          v-model="form.description"
          id="description"
          type="description"
          textarea
          placeholder="Escreva a sua proposta aqui"
          :errors="errors.description"
          @change="resetErrors"
        />
        <div class="d-flex justify-content-end">
          <small
            class="font-weight-bold text-dark"
            :class="{ 'text-danger': form.description.length > 200 }"
          >
            {{ form.description.length }}/200
          </small>
        </div>
        <hr class="my-2 mb-3" />
        <p class="indicator text-center text-dark" style="font-size: 14px;">
          Informe quanto você gostaria de cobrar pelo serviço realizado:
        </p>
        <form-group
          v-model="form.price"
          class="mb-2"
          id="price"
          isMoney
          :moneyParams="moneyParams"
          placeholder="0"
          :errors="errors.price"
          @change="resetErrors"
        />
        <p class="indicator text-center text-dark" style="font-size: 14px;">
          Informe o prazo em dias para entregar esse projeto:
        </p>
        <div class="d-flex align-items-center">
          <form-group
            v-model="form.deadline"
            id="deadline"
            type="number"
            class="mb-2"
            placeholder="0"
            :errors="errors.deadline"
            @change="resetErrors"
          />
          <p class="pl-4 pt-2 text-dark">
            Dias
          </p>
        </div>
        <div class="d-flex align-items-center justify-content-between">
          <button
            class="btn btn-h-sm btn-outline-secondary btn-block"
            @click="close"
          >
            Cancelar
          </button>
          <button
            class="btn btn-h-sm btn-primary btn-block ml-3 mt-0"
            @click="submit"
            :disabled="isValidFields"
          >
            Enviar proposta
          </button>
        </div>
      </div>
    </responsive-modal>
    <confirmation
      iconType="check"
      title="Concluído!"
      v-model="success"
      confirmButtonClass="btn btn-primary"
      confirmButtonText="Fechar"
      text="Sua proposta foi enviada com sucesso!"
      @confirm="onClose"
    />
  </div>
</template>
<script>
import { formFields } from "@/functions/helpers.js";
import { mapState, mapActions } from "vuex";
import { authMixin } from "@/mixins";

export default {
  mixins: [authMixin],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    opportunity: {
      type: Object,
      default: () => {}
    }
  },
  model: {
    prop: "value",
    event: "input"
  },
  watch: {
    value(v) {
      this.$emit("input", v);
      const { form, errors } = formFields(["description", "price", "deadline"]);
      this.form = form;
      this.errors = errors;
    }
  },
  data() {
    return {
      ...formFields(["description", "price", "deadline"]),
      success: false,
      moneyParams: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false
      }
    };
  },
  computed: {
    ...mapState("user", ["user"]),
    isValidFields() {
      return (
        !this.form.description.length &&
        !this.form.price.length &&
        !this.form.deadline.length
      );
    },
    proposalToSubmit() {
      return {
        user: this.user.id,
        project: this.opportunity.id,
        ...this.form
      };
    }
  },
  methods: {
    ...mapActions("opportunity", ["sendProposal"]),
    close() {
      this.$emit("close-modal", false);
    },
    submit() {
      this.sendProposal(this.proposalToSubmit)
        .then(() => {
          this.$message.success("Proposta enviada com sucesso!");
          this.$emit("update-proposals");
          this.close();
        })
        .catch(this.messageError);
    },
    messageError(e) {
      const errorData = e.response.data;

      if (errorData?.error === "Proposal already exists!") {
        this.$message.error("Você já enviou uma proposta!");
      } else {
        this.$message.error(errorData.error);
      }
    },
    onClose() {
      this.$emit("input", false);
    },
    resetErrors() {
      this.errors = {
        email: [],
        password: [],
        non_field_errors: []
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.components-proposal-new {
  p {
    font-size: 14px !important;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.015em;
    color: #595959;
  }
  .new-proposal-comp {
    ::v-deep hr {
      border-top: 1px solid #8c8c8c !important;
    }
    .indicator {
      font-size: 14px !important;
      font-style: normal;
      font-weight: 500;
      letter-spacing: 0.015em;
      color: #595959;
    }
  }
}
</style>
