<template>
  <div class="components-opportunity-item p-3 pt-lg-4 mb-4">
    <div v-if="isMy" class="mb-4">
      <div class="row align-items-center mb-4 mb-lg-0">
        <div class="col-lg-10 order-1 order-lg-0">
          <h3
            class="project-title text-center text-lg-left flex-fill text-dark"
          >
            {{ opportunity.title }}
          </h3>
        </div>
        <div class="col-lg-2 text-right order-0 order-lg-1">
          <b-dropdown
            v-if="isRunning"
            no-caret
            variant="link"
            class="text-decoration-none p-0 m-0"
          >
            <template #button-content>
              <h1 class="m-0 p-0"><i class="icon-options text-dark" /></h1>
            </template>
            <b-dropdown-item-button @click="onEditService" class="order-option">
              Editar
            </b-dropdown-item-button>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item-button
              @click="onCompleteService"
              class="order-option"
            >
              Concluir
            </b-dropdown-item-button>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item-button
              @click="onCloseService"
              class="order-option text-danger"
            >
              <span class="text-danger">Encerrar</span>
            </b-dropdown-item-button>
          </b-dropdown>
        </div>
      </div>
      <div class="d-lg-flex align-items-center">
        <div class="mr-4">
          <small class="text-dark">
            Categoria: <strong>{{ opportunity.category.name }}</strong>
          </small>
        </div>
        <div>
          <small class="text-dark">
            Status:
            <span :class="['status-tag', `${opportunity.status}`]">{{
              getStatusTranslated(opportunity.status)
            }}</span>
          </small>
        </div>
      </div>
    </div>
    <div v-else class="row  mb-4 mb-lg-0">
      <div class="col-lg-8">
        <h3
          class="project-title text-center text-lg-left text-uppercase flex-fill text-dark mb-3"
        >
          {{ opportunity.title }}
        </h3>
      </div>
      <div class="col-lg-4">
        <div class="">
          <small class="text-dark">
            Categoria: <br v-if="opportunity.category.name.length > 17" />
            <strong>{{ opportunity.category.name }}</strong>
          </small>
        </div>
      </div>
    </div>
    <div class="row mb-2 mb-lg-3">
      <div
        v-if="opportunity.cover_thumbnail"
        class="col-lg-4 mb-3 mb-lg-0 text-center text-lg-left"
      >
        <img
          class="opportunity-img img-fluid"
          :src="opportunity.cover_thumbnail"
          :alt="opportunity.title"
        />
      </div>
      <div
        :class="{
          'col-lg-8': opportunity.cover_thumbnail,
          col: !opportunity.cover_thumbnail
        }"
      >
        <h6 class="text-dark font-weight-bold mb-3">Briefing:</h6>
        <p class="briefing text-dark mb-0">
          <span v-html="opportunity.briefing"></span>
        </p>
      </div>
    </div>
    <div
      v-if="opportunity.tags && opportunity.tags.length"
      class="d-lg-flex mb-5"
    >
      <div class="text-dark service-title mb-2 mb-lg-0 mr-2">
        Serviços:
      </div>
      <div class="tag-wrapper">
        <div
          v-for="(tag, i) in opportunity.tags"
          :key="`tag-${i}`"
          class="mb-2"
        >
          <div class="tag text-wrap mr-1">
            {{ tag.name }}
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-4 pt-lg-0">
      <div class="col-lg-3 pr-lg-0 mb-3 mb-lg-0">
        <div
          class="d-flex align-items-center justify-content-center justify-content-lg-start"
        >
          <i class="icon icon-history mr-1"></i>
          <small class="text-dark">
            <strong>Publicação: </strong>
            {{ published_at }}
          </small>
        </div>
      </div>
      <div class="col-lg-3 mb-3 mb-lg-0">
        <div
          class="d-flex align-items-center justify-content-center justify-content-lg-start"
        >
          <i class="icon icon-calendar mr-1"></i>
          <small class="text-dark">
            <strong>Prazo: </strong>
            {{ deadline }}
          </small>
        </div>
      </div>
      <div class="col-lg-4">
        <div
          class="d-flex align-items-center justify-content-center justify-content-lg-start"
        >
          <i class="icon icon-proposal mr-1"></i>
          <small class="text-dark">
            <strong>Propostas recebidas: </strong>
            {{ opportunity.proposal_count }}
          </small>
        </div>
      </div>
    </div>
    <div class="d-none d-lg-flex justify-content-center justify-content-lg-end">
      <button v-if="isMy" class="btn btn-dark" @click="seeProposals">
        Visualizar propostas
      </button>
      <button
        v-else-if="!isMy"
        class="btn btn-dark"
        @click="$emit('on-new-proposal', opportunity)"
      >
        Enviar proposta
      </button>
    </div>
    <div class="d-lg-none">
      <button v-if="isMy" class="btn btn-dark btn-block" @click="seeProposals">
        Visualizar propostas
      </button>
      <button
        v-else-if="!isMy"
        class="btn btn-primary btn-block"
        @click="$emit('on-new-proposal', opportunity)"
      >
        Enviar proposta
      </button>
    </div>
    <confirmation
      v-model="show_confirm_end_service"
      title="Encerramento"
      :text="
        `Ao encerrar o serviço, ele não ficará mais disponível para envio de propostas,
        isto significa que você cancelou o serviço antes da sua conclusão.
        Tem certeza que deseja encerrar este serviço: '${opportunity.title}'?`
      "
      icon-type="warning"
      confirm-button-text="Encerrar"
      cancel-button-text="Cancelar"
      cancel-button-class="btn-primary"
      confirm-button-class="btn-outline-dark"
      @cancel="show_confirm_end_service = false"
      @confirm="finishProject"
    />

    <confirmation
      v-model="show_confirm_complete_service"
      title="Conclusão"
      text="A conclusão é feito após a conclusão e entrega do serviço pelo profissional. Tem certeza que deseja concluir este serviço?"
      icon-type="success"
      confirm-button-text="Concluir"
      cancel-button-text="Cancelar"
      cancel-button-class="btn-primary"
      confirm-button-class="btn-outline-dark"
      @cancel="show_confirm_complete_service = false"
      @confirm="completeProject"
    />
    <confirmation
      v-model="show_confirm_respost_service"
      title="Conclusão"
      text="Ao retomar o serviço, ele aparecerá novamente na lista de oportunidades para receber propostas."
      icon-type="success"
      confirm-button-text="Concluir"
      cancel-button-text="Cancelar"
      cancel-button-class="btn-primary"
      confirm-button-class="btn-outline-dark"
      @cancel="show_confirm_respost_service = false"
      @confirm="resumeProject"
    />
  </div>
</template>
<script>
import moment from "moment";
import constants from "@/constants.js";
// import Confirmation from "../Confirmation.vue";

export default {
  props: {
    isMy: {
      type: Boolean,
      default: false
    },
    opportunity: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      status: constants.JSON.STATUS,
      show_confirm_end_service: false,
      show_confirm_complete_service: false,
      show_confirm_respost_service: false
    };
  },
  computed: {
    isRunning() {
      return (
        this.opportunity.status == "in_progress" ||
        this.opportunity.status == "posted"
      );
    },
    published_at() {
      return moment(this.opportunity.get_created_at).fromNow();
    },
    deadline() {
      if (!this.opportunity.deadline) return "Não informado";
      return moment(this.opportunity.deadline).format("DD/M/YYYY");
    }
  },
  methods: {
    onEditService() {
      this.$router.push({
        name: "edit-opportunity",
        params: { id: this.opportunity.id }
      });
    },
    onRepostService() {
      this.show_confirm_respost_service = true;
    },
    onCompleteService() {
      this.show_confirm_complete_service = true;
    },
    onCloseService() {
      this.show_confirm_end_service = true;
    },
    finishProject() {
      this.$emit("finish-project", this.opportunity);
    },
    completeProject() {
      this.$emit("complete-project", this.opportunity);
    },
    resumeProject() {
      this.$emit("resume-project", this.opportunity);
    },
    getStatusTranslated(stat) {
      return this.status.find(s => s?.slug === stat)?.name;
    },
    async seeProposals() {
      const { data: proposals } = await this.$store.dispatch(
        "opportunity/getProjectProposals",
        this.opportunity.id
      );
      this.$emit("show-proposals", {
        opportunity: this.opportunity,
        proposals
      });
    }
  }
  // components: { Confirmation }
};
</script>
<style lang="scss" scoped>
.components-opportunity-item {
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
  background: #f5f5f5;
  border-radius: 16px;
  position: relative;
  width: 100%;

  ::v-deep .dropdown {
    .btn:not(.btn-sm) {
      min-width: 32px !important;
      width: 32px !important;
      padding: 6px;
    }

    .dropdown-item {
      padding: 0px;
      &.text-danger {
        color: red !important;
      }
    }
  }

  .project-title {
    word-wrap: break-word;
    overflow: hidden;
    word-wrap: normal;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-clamp: 2; /* number of lines to show */
    box-orient: vertical;
    @media screen and (max-width: 768px) {
      display: block;
    }
  }

  .opportunity-img {
    border-radius: 8px;
  }

  p {
    overflow-wrap: break-word;
    text-align: justify;

    @media screen and (max-width: 768px) {
      font-size: 12px;
    }
  }

  .icon {
    font-size: 22px;
    color: #202124;
  }

  .status-tag {
    box-sizing: border-box;
    border-radius: 4px;
    padding: 3px;
    font-weight: bold;

    &.in_progress {
      border: 1px solid #914bf2;
      color: #914bf2;
    }

    &.complete {
      border: 1px solid #41bf6d;
      color: #41bf6d;
    }

    &.posted {
      border: 1px solid #41bf6d;
      color: #41bf6d;
    }

    &.closed {
      border: 1px solid #000;
      color: #000;
    }
  }

  .tag-wrapper {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    word-break: break-word;
    white-space: nowrap;
    overflow: hidden;
  }
}
</style>
